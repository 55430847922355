import "./footer.css"
import "./footer_fhd.css"
import "./footer_4k.css"
import "./footer_mobile.css"
import { Link } from "react-router-dom"

export default function Footer(props) {
    const { locale } = props.app
    return (
        <footer className="footer">
            <div className="footer-logo">
                <img src="https://hanymany.com/assets/logo-gray.svg" alt="hanymany-logo" />
                <p>hanymany</p>
            </div>
            <div className="footer-info flex">
                <div className="info-element flex">
                    <i className="fa-regular fa-envelope"></i>
                    <a href={`mailto:${locale[0]}`}>{locale[0]}</a>
                </div>
                <div className="info-element flex">
                    <i className="fa-solid fa-phone"></i>
                    <a href={`tel:${locale[1]}`}>{locale[1]}</a>

                </div>
                <div className="info-element flex">
                    <i className="fa-solid fa-location-dot"></i>
                    <a href='#'>{locale[2]}</a>
                </div>
            </div>
            <div className="footer-social flex">
                <Link to={locale[4]}>
                    <i className="fa-brands fa-2x fa-twitter"></i>
                </Link>
                <Link to={locale[5]}>
                    <i className="fa-brands fa-2x fa-facebook"></i>
                </Link>
                <Link to={locale[6]}>
                    <i className="fa-brands fa-2x fa-instagram"></i>
                </Link>
                <Link to={locale[7]}>
                    <i className="fa-brands fa-2x fa-tiktok"></i>
                </Link>
                <Link to={locale[8]}>
                    <i className="fa-brands fa-2x fa-youtube"></i>
                </Link>
            </div>
            <div className="footer-bottom">
                <p>{locale[3]}</p>
                <div className="footer-lines flex">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </footer>
    )
}