import axios from "axios";
import { useEffect,  useState } from "react";
import { Link, useSearchParams } from "react-router-dom"
import BlogSlider from "../../components/blog-slider/blog-slider";
import GetConfig from "../../config/getConfig";

import "./blog-post.css"


const getMonth = function (idx, locale) {
    var objDate = new Date();
    objDate.setDate(1);
    objDate.setMonth(idx - 1);

    var month = objDate.toLocaleString(locale, { month: "long" });

    return month;
}

export default function BlogPost(props) {
    const [searchParams] = useSearchParams();
    const { locale, language } = props
    const [post, setPost] = useState();
    const [loading, setloading] = useState(true);
    useEffect(() => {
        setloading(true);
        axios.get(GetConfig().blog + "/" + searchParams.get("_id"))
            .then(res => {
                setPost(res.data);
                setloading(false);
            })

    }, [])

    if(loading) {
        return (
            <p>Loading ...</p>
        )
    } else {
        return (
            <div className="page post-page">
                <div className="post-main">
                    <Link to="/blog" className="yellow2 f600">{locale[0]}</Link>
                    <h1 className="f700">{post.title}</h1>
                    <p className="date">{new Date(post.date).getDate()} {getMonth(new Date(post.date).getMonth(), language)} {new Date(post.date).getFullYear()}</p>
                    <img src={post.image} alt="blog-post-img" />
                    <h2>{post.subtitle}</h2>
                    <p>{post.text}</p>
                    <div id="main-content" dangerouslySetInnerHTML={{__html: JSON.parse(post.html)}}>
                    </div>

                    <div className="post-author flex">
                        <img src={post.authorImg} alt="author" />
                        <div className="authorText">
                            <h4>{post.authorName}</h4>
                            <h5>{post.authorPosition}</h5>
                        </div>
                    </div>
                </div>
                <div className="post-other">
                    <h3>{locale[1]}</h3>
                    <BlogSlider locale={{ 54: "Devamı" }} />
                </div>
            </div>
        )
    }
}