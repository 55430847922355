import axios from "axios";
import { useState } from "react";
import GetConfig from "../../config/getConfig";

export default function ContactForm(props) {
    const { locale } = props
    const [contactForm, setcontactForm] = useState({});
    const [formloading, setformloading] = useState(false);
    const [formerror, setformerror] = useState();

    function inpChange(e) {
        setcontactForm({
            ...contactForm,
            [e.target.name]: e.target.value
        })
    }
    async function postForm() {
        if (!formloading) {
            setformloading(true)
            setformerror()
            await axios.post(GetConfig().contactform, contactForm)
            .then((res) => {
                if(res.data === 'Saved Form') {
                    setformerror('Sent!')
                } else {
                    setformerror('Something went wrong please try again')
                }
            })
            .catch((err) => {
                setformerror("Incorrect Information")
            })
            setformloading(false)
        }
    }
    return (
        <div className="contact-form" >
            <input type="text" name="name" onChange={(e) => { inpChange(e) }} placeholder={locale[57]} />
            <input type="email" name="email" onChange={(e) => { inpChange(e) }} placeholder={locale[58]} />
            <input type="phone" name="phone" onChange={(e) => { inpChange(e) }} placeholder={locale[59]} />
            <textarea name="message" id="message" cols="30" rows="10" onChange={(e) => { inpChange(e) }} placeholder={locale[60]} > </textarea>
            <button className={`bgyellow ${formloading? 'button-disabled' : ''}`} onClick={() => { postForm() }}> {locale[61]} </button>
            {formerror? <div className="error-box">{formerror}</div> : ''}
        </div>
    )
}