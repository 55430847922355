import "./about.css"
import "./about_fhd.css"
import "./about_4k.css"
import "./about_mobile.css"

export default function About(props) {
    const { locale } = props
    return (
        <div className="page about-page">
            <div className="bg-about">
                <img src="https://hanymany.com/assets/hexagon-bg.svg" alt="hexagon background" />
            </div>
            <h2 className="yellow2 f500">{locale[0]}</h2>
            <h1>{locale[1]}</h1>
            <h4 className="f500">{locale[2]}</h4>
            <img src="https://hanymany.com/assets/about.jpg" alt="about" />
            <h3 className="f600 yellow">{locale[3]}</h3>
            <p className="f500 ">{locale[4]}</p>
            <p className="f400">{locale[5]}</p>
        </div>
    )
}