import axios from "axios";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import GetConfig from "../../config/getConfig";
import "./applyform.css"
export default function ApplyForm(props) {
    const [file, setfile] = useState();
    const [error, seterror] = useState();
    const [ferror, setferror] = useState();
    const [formState, setformState] = useState();
    const [loading, setloading] = useState(false);

    function handleInput(e) {
        setformState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    function handleFile(e) {
        setfile(e.target.files[0])
    }

    async function handleSubmit() {
        setferror()
        if(!loading) {
            if (formState) {
                if (formState.name && formState.email && formState.hear && formState.question1 && formState.question2 && file) {
                    setloading(true)
                    var fd = new FormData()
                    fd.append('files', file, file.name)
                    var statebody = Object.assign({}, formState, { files: null })
                    fd.append('state', JSON.stringify(statebody))
                    axios.post(GetConfig().postform, fd)
                        .then((res) => {
                            if(res.data === 'Saved Form') {
                                setferror('Form Saved!')
                            }
                        }).catch((e) => {
                            console.log(e)
                        })
                    setloading(false)
                } else {
                    setferror('Invalid Information')
                }
            } else {
                setferror('Invalid Information')
            }
        }

    }

    useEffect(() => {
        if (file) {
            if (file.size > 4194304) {
                seterror(locale[16])
                setfile()
            } else {
                seterror()
            }
        }
    }, [file]);
    const { locale } = props
    return (
        <div className="apply-form">
            <input onChange={(e) => { handleInput(e) }} name="name" type="text" placeholder={locale[2]} />
            <input onChange={(e) => { handleInput(e) }} name="email" type="email" placeholder={locale[3]} />
            <div className="filebox">
                <div className="flex-a">
                    <div className="flex-b">
                        <i className="fa-solid fa-paperclip fa-2x"></i>
                        <div className="filetext">
                            <p>{locale[4]}</p>
                            <p>{locale[14]}</p>
                        </div>
                    </div>
                    <label className="file-input}">
                        <input type="file" name="file" id="file" onChange={(e) => { handleFile(e) }} />
                        {file ? file.name : locale[15]}
                        <p className="errorbox">{error ? error : ""}</p>
                    </label>
                </div>
            </div>
            <label className="f600" htmlFor="hear">{locale[5]}</label>
            <textarea onChange={(e) => { handleInput(e) }} name="hear" id="hear" cols="30" rows="5" placeholder={locale[6]}></textarea>
            <p className="f600" >{locale[7]}</p>
            <p className="f600" ><Link to={'https://www.speakpipe.com/voice-recorder'}>{locale[8]}</Link></p>
            <label className="f600" htmlFor="q1">{locale[9]}</label>
            <input onChange={(e) => { handleInput(e) }} name="question1" type="text" id="q1" placeholder={locale[10]} />
            <label className="f600" htmlFor="q2">{locale[11]}</label>
            <input onChange={(e) => { handleInput(e) }} name="question2" type="text" id="q2" placeholder={locale[12]} />
            <button className={`bgyellow f700 ${loading ? 'button-disabled' : ''}`} onClick={() => { handleSubmit() }}>{locale[13]}</button>
            
                {ferror ?<div className="error-box"> {ferror} </div>: ""}
            
        </div>
    )
}