import { useEffect, useState } from "react"
import BlogCard from "../../components/blog-card/blog-card"
import GetConfig from "../../config/getConfig"
import "./blog.css"
import "./blog_4k.css"
import "./blog_fhd.css"
import "./blog_mobile.css"

async function getBlogPosts() {
    const response = await fetch(GetConfig().blog)
    const data = await response.json()
    return data;
}

export default function Blog(props) {
    const { locale, language } = props
    const [blog_posts, setblog_posts] = useState([]);
    const [loading, setloading] = useState(true);

    useEffect(() => {
        setloading(true)
        getBlogPosts()
        .then(res=>setblog_posts(res))
        setloading(false)
    }, [])


    const [page, setPage] = useState(1);

    if (loading) { return (<p>Loading...</p>) }
    else {
        return (
            <div className="page blog-page">
                <h1 className="f500">{locale[0]}</h1>
                <div className="blog-renderer">
                    {
                        blog_posts.map((e, i) => {
                            if (page === 1) {
                                if (i < 12) {
                                    return (
                                        <BlogCard language={language} key={"blogc" + i} e={e} i={i} locale={{ 54: locale[1] }} />
                                    )
                                }
                            } else {
                                if (page * 12 > (i) && (i) > ((page * 12) - 13)) {
                                    return (
                                        <BlogCard language={language} key={"blogc" + i} e={e} i={i} locale={{ 54: locale[1] }} />
                                    )
                                }
                            }
                        })

                    }
                </div>
                <Pagination totalPages={Math.ceil(blog_posts.length / 12)} currentPage={page} pageSize={12} setPage={setPage} />
            </div>
        )
    }
}

const Pagination = ({ totalPages, currentPage, pageSize, setPage }) => {
    if (totalPages <= 1) return null;

    let startPage = 1;
    let endPage = Math.min(totalPages, 3);

    if (currentPage > 2) {
        startPage = currentPage - 1;
        endPage = Math.min(totalPages, startPage + 3);
    }

    return (
        <div className="page-select">
            {currentPage > 1 && (
                <img onClick={() => { setPage(currentPage - 1) }} className="page-arrow page-arrow-left" src="https://hanymany.com/assets/slider-left.svg" alt="arrow" />
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(page => (
                <div key={page} className={`blog-hexagon ${page === currentPage ? 'active-hex' : ''}`} onClick={() => { setPage(page) }}>
                    <div className="select-hexagon">
                    </div>
                    <p>{page}</p>
                </div>
            ))}
            {currentPage < totalPages && (
                <img onClick={() => { setPage(currentPage + 1) }} className="page-arrow page-arrow-right" src="https://hanymany.com/assets/slider-right.svg" alt="arrow" />

            )}
        </div>
    );
};