import { useEffect, useState } from "react";
import Slider from "react-slick";
import GetConfig from "../../config/getConfig";
import BlogCard from "../blog-card/blog-card";

async function getBlogPosts() {
    const response = await fetch(GetConfig().blog)
    const data = await response.json()
    return data;
}

export default function BlogSlider({ locale }) {
    const [loading, setloading] = useState(true);
    const [blogArray, setBlogArray] = useState();

    useEffect(() => {
        setloading(true)
        getBlogPosts()
            .then(res => {
                setBlogArray(res)
                setloading(false)
            })
    }, [])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <Arrow type={false} />,
        prevArrow: <Arrow type={true} />,
        responsive: [

            {
                breakpoint: 2600,
                settings: {
                    slidesToScroll: 4,
                    slidesToShow: 4
                }
            }, {
                breakpoint: 2000,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3
                }
            }, {
                breakpoint: 1450,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2
                }
            }, {
                breakpoint: 960,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1
                }
            },
        ]
    };
    if (loading) {
        return (
            <p>Loading ...</p>
        )
    } else {
        return (
            <Slider {...settings} className="slidercomponent">
                {
                    blogArray.map((e, i) => {
                        return (
                            <BlogCard key={"sliderkey-" + i} e={e} locale={locale} i={i} />
                        )
                    })
                }
            </Slider>
        )
    }
}
export function Arrow(props) {
    return (
        <img onClick={props.onClick} className={`slider-arrow slider-arrow-${props.type ? "left" : "right"}`} src={`https://hanymany.com/assets/slider-${props.type ? "left" : "right"}.svg`} alt="arrow" />
    )
}