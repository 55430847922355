import "./apply.css"
import "./apply_fhd.css"
import "./apply_4k.css"
import "./apply_mobile.css"
import ApplyForm from "../../components/applyform/applyform"

export default function Apply(props) {
    const { locale } = props
    return (
        <div className="page apply-page">
            <div className="flex">
                <div className="flex-form fdiv">
                    <div className="bg-apply">
                        <img src="https://hanymany.com/assets/hexagon-bg.svg" alt="hexagon background" />
                    </div>
                    <h2 className="yellow2 f600">{locale[0]}</h2>
                    <h1 className="f600">{locale[1]}</h1>
                    <ApplyForm locale={locale} />
                </div>
                <div className="flex-img fdiv">
                    <img src="https://hanymany.com/assets/applybg.jpg" alt="apply-img" />
                </div>
            </div>
        </div>
    )
}