import { Link } from "react-router-dom";
import "./blog-card.css";
const getMonth = function (idx, locale) {

    var objDate = new Date();
    objDate.setDate(1);
    objDate.setMonth(idx);

    var month = objDate.toLocaleString(locale, { month: "long" });

    return month;
}

export default function BlogCard(props) {
    const { e, i, locale, language } = props
    return (
        <div className="blog-item" key={"slider" + i}>
            <div className="blog-img">
                <img src={e.image} alt="blogimg" />
            </div>
            <div className="blog-profile flex">
                <img src={e.authorImg} alt="blogpimg" />
                <p className="f600">{e.authorName}</p>
            </div>
            <div className="blog-title">
                <h3>{e.title}</h3>
            </div>
            <div className="blog-continue flex">
                <p>{new Date(e.date).getDate()} {getMonth(new Date(e.date).getMonth(), language)} {new Date(e.date).getFullYear()}</p>
                <Link to={`/post?_id=${e._id}`} className="hexagon">{locale[54]}</Link>
            </div>
        </div>
    )
}