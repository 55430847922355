import { Link } from "react-router-dom"
import "./landing.css"
import "./landing_mobile.css"
import "./landing_fhd.css"
import "./landing_4k.css"
import BlogSlider from "../../components/blog-slider/blog-slider"
import ContactForm from "../../components/form/contactform"
import ReactTypingEffect from 'react-typing-effect';

export default function Landing(props) {
    
    const { locale } = props
    return (
        <div className="page landing">
            <div className="background z1">
                <img src="https://hanymany.com/assets/background.svg" alt="background-bee" />
            </div>
            <div className="flex e-2 p-n mflex z2">
                <div className="text-section">
                    <h1 className="f500">{<ReactTypingEffect
                        speed={100}
                        typingDelay={500}
                        text={[locale[0], locale[62], locale[63], locale[64], locale[65]]}
                    />}</h1>
                    <h2 className="f500 yellow">{locale[1]}</h2>
                    <p className="f500">{locale[2]}</p>
                    <div className="flex">
                        {
                            [
                                {
                                    text: locale[3],
                                    path: '/apply'
                                },
                                {
                                    text: locale[4],
                                    path: '/about'
                                }
                            ].map((e, i) => {
                                return (
                                    <Link className={"hexagon hexagon-" + i} key={e.text} to={e.path}> {e.text} </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="svg-section">
                    <img src="https://hanymany.com/assets/target.png" alt="target" />
                </div>
            </div>
            <div className="cards z2">
                <div className="card-header">
                    <h2 className="f600">{locale[5]}</h2>
                    <h3 className="f500 yellow">{locale[6]} <i className="fa-solid fa-magnifying-glass"></i></h3>
                </div>
                <div className="card-renderer">
                    {[
                        {
                            img: 'https://hanymany.com/assets/honeybee.png',
                        },
                        {
                            img: 'https://hanymany.com/assets/wasp.png',
                        },
                        {
                            img: 'https://hanymany.com/assets/maverickbee.png',
                        },
                    ].map((e, i) => {
                        return (
                            <div className={`card bee-card-${i}`} key={"card" + i}>
                                <div className="inner-card">
                                    <div className="card-bg">
                                        <img src="https://hanymany.com/assets/hexagon-bg.svg" alt="hex-bg" />
                                    </div>
                                    <div className="card-img">
                                        <img src={e.img} alt="bee-vector" />
                                    </div>
                                    <h3 className="f700">{locale[7 + i]}</h3>
                                    <p className="f600">{locale[10 + i]}</p>
                                </div>
                            </div>
                        )
                    })

                    }
                </div>
            </div>
            <div className="boxes z2">
                <h1>{locale[13]} <span className="yellow2">{locale[14]}</span></h1>
                <div className="flex mflex">
                    <div className="box-renderer">
                        {
                            [
                                "box",
                                "box",
                                "box",
                                "box",
                                "box",
                                "box"
                            ].map((e, i) => {
                                return (
                                    <div className="box flex" key={e + i}>
                                        <div className="box-text">
                                            <h5 className="f700 yellow2">{locale[15 + i]}</h5>
                                            <p className="f500">{locale[21 + i]}</p>
                                        </div>
                                        <img src={"https://hanymany.com/assets/" + e + (i + 1) + ".svg"} alt="bee" className="boximg" />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="box-svg">
                        <img src="https://hanymany.com/assets/laptopdesk.jpg" alt="woman-sitting-laptop-illustration" />
                    </div>
                </div>
            </div>
            <div className="statistics z2">
                <h1 className="f600">{locale[27]} <span className="yellow2 f600">{locale[28]}</span></h1>
                <div className="bg-hex">
                    <img src="https://hanymany.com/assets/hexagon-bg.svg" alt="hexagon background" />
                </div>
                <div className="bg-hex bg-hex-r">
                    <img src="https://hanymany.com/assets/hexagon-bg.svg" alt="hexagon background" />
                </div>
                <div className="flex mflex howtobee">

                    <img src="https://hanymany.com/assets/honeycumb.svg" alt="honeycumb" className="sfimg" />
                    <div className="statistics-text">
                        <h2 className="yellow2">{locale[29]}</h2>
                        <p className="f500">{locale[30]}</p>
                    </div>
                </div>
                <div className="mflex flex stat-boxes">
                    {
                        [
                            "sbox",
                            "sbox",
                            "sbox",
                            "sbox",
                        ].map((e, i) => {
                            return (
                                <div className="stat-box" key={e + i}>
                                    <div className="align-right">
                                        <img src={`https://hanymany.com/assets/${e + (i + 1)}.svg`} alt="happyicons" />
                                    </div>
                                    <h1 className="f600">{locale[31 + i]}</h1>
                                    <div className="align-left">
                                        <h4 className="f700">{locale[35 + i]}</h4>
                                        <h6 className="f400">{locale[39 + i]}</h6>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="process z2">
                <h2 className="yellow2 f700">{locale[43]}</h2>
                <h1 className="f600">{locale[44]}</h1>
                <div className="stair-boxes mflex">
                    {
                        [
                            "stbox",
                            "stbox",
                            "stbox",
                            "stbox",
                        ].map((e, i) => {
                            return (
                                <div className={`stair-box ${i % 2 === 0 ? 'low-stair-box' : 'high-stair-box'}`} key={e + i}>
                                    <h3 className="yellow2 f700">{i + 1}</h3>
                                    <img src={`https://hanymany.com/assets/stbox1.svg`} alt="process-icons" />
                                    {/* <img src={`https://hanymany.com/assets/${e + (i + 1)}.svg`} alt="process-icons" /> */}
                                    <h4 className="f700">{locale[45 + i]}</h4>
                                    <p className="f700">{locale[49 + i]}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="blog z2">
                <div className="bg-square">
                    <img src="https://hanymany.com/assets/blog-bg.svg" alt="square background" />
                </div>
                <h1>{locale[53]}</h1>
                <BlogSlider locale={locale} />
            </div>
            <div className="contact z2">
                <h2 className="yellow2 f600">{locale[55]}</h2>
                <h1 className="f700">{locale[56]}</h1>
                <ContactForm locale={locale} />
            </div>
        </div >
    )
}