import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/template-components/footer/footer";
import Header from "./components/template-components/header/header";
import Loading from "./components/loading/loading";

import About from "./pages/about/about";
import Apply from "./pages/apply/apply";
import BlogPost from "./pages/blog-post/blog-post";
import Blog from "./pages/blog/blog";
import Landing from "./pages/landing/landing";
import axios from "axios";
import GetConfig from "./config/getConfig";
import { computeHeadingLevel } from "@testing-library/react";


function App() {
  const [language, setLanguage] = useState("tr");
  const [loading, setloading] = useState(true);
  const [locales, setlocales] = useState();

  useEffect(() => {
    setloading(true)
    async function getLocale() {
      await axios.get(GetConfig().locale)
        .then((res) => {
          const setlcx = res.data
          delete setlcx[0].lang
          delete setlcx[1].lang

          const setLcl = {
            en: setlcx[0],
            tr: setlcx[1],
          }

          setlocales(setLcl)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getLocale();
    setloading(false)

  }, []);



  if (loading) {
    return (<Loading />)
  } else {
    if (locales) {
      return (
        <div className="App">
          <BrowserRouter>
            <Header app={{
              locale: locales[language]["header"],
              language,
              setLanguage
            }} />
            <Routes>
              <Route exact path="/" element={<Landing language={language} locale={locales[language]["landing"]} />} />
              <Route exact path="/blog" element={<Blog language={language} locale={locales[language]["blog"]} />} />
              <Route exact path="/about" element={<About language={language} locale={locales[language]["about"]} />} />
              <Route exact path="/apply" element={<Apply language={language} locale={locales[language]["apply"]} />} />
              <Route exact path="/post" element={<BlogPost language={language} locale={locales[language]["post"]} />} />
            </Routes>
            <Footer app={{
              locale: locales[language]["footer"]
            }} />
          </BrowserRouter>
        </div>

      );
    } else {
      return (<Loading />)

    }
  }
}

export default App;
