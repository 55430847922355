const local = false
const root = local? 'http://localhost:5000/' : "https://api.hanymany.com/"


const config = {
    locale: root + "locale",
    contactform: root + "contactform",
    postform: root + "postform",
    blog: root + "blog",
}

export default function GetConfig() {
    return config;
}